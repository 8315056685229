import * as React from 'react';
import { sourcesApiSlice } from 'modules';
// hook is required to get meta from source request
// we cannot get it in normal way because of chunks logic
export var useSourcesMeta = function useSourcesMeta(_ref) {
    var role = _ref.role, filter = _ref.filter, directionFieldId = _ref.directionFieldId, knowledgeFieldId = _ref.knowledgeFieldId;
    var params = React.useMemo(function () {
        var result = {
            role: role,
            // mock page, we need only meta
            page: 1,
            query: filter,
            functional_direction_id: directionFieldId
        };
        if (knowledgeFieldId) {
            result.knowledge_field_id = knowledgeFieldId;
        }
        return result;
    }, [directionFieldId, filter, knowledgeFieldId, role]);
    var _sourcesApiSlice$useG = sourcesApiSlice.useGetSourcesQuery(params, {
        skip: !directionFieldId
    }), sourcesData = _sourcesApiSlice$useG.data;
    return {
        meta: sourcesData === null || sourcesData === void 0 ? void 0 : sourcesData.meta
    };
};
