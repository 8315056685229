var _QUESTION_STATUS_TEXT, _QUESTION_USERS_STATU, _QUESTION_ADMIN_STATU, _QUESTION_USERS_STATU2;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
import { QUESTION_STATUS } from 'modules/questions';
export var QUESTION_STATUS_TEXT = (_QUESTION_STATUS_TEXT = {}, _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUS.approved, 'Да'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUS.rework, 'Нет'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUS.expertise, 'Нет'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUS.editing, 'Нет'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUS.rejected, 'Нет'), _QUESTION_STATUS_TEXT);
export var QUESTION_USERS_STATUS_TEXT = (_QUESTION_USERS_STATU = {}, _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUS.approved, 'Утвержден'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUS.rework, 'На редактуре'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUS.expertise, 'На экспертизе'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUS.editing, 'Не проверен'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUS.rejected, 'Отклонен'), _QUESTION_USERS_STATU);
export var QUESTION_ADMIN_STATUS_TEXT = (_QUESTION_ADMIN_STATU = {}, _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUS.approved, 'Утвержден экспертом'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUS.rework, 'На редактуре'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUS.expertise, 'На экспертизе'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUS.editing, 'Не проверен'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUS.rejected, 'Отклонен'), _QUESTION_ADMIN_STATU);
export var QUESTION_USERS_STATUS_OPTIONS_RAW = [{
        id: QUESTION_STATUS.approved.toString(),
        value: QUESTION_STATUS.approved.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUS.approved]
    }, {
        id: QUESTION_STATUS.editing.toString(),
        value: QUESTION_STATUS.editing.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUS.editing]
    }, {
        id: QUESTION_STATUS.expertise.toString(),
        value: QUESTION_STATUS.expertise.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUS.expertise]
    }, {
        id: QUESTION_STATUS.rejected.toString(),
        value: QUESTION_STATUS.rejected.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUS.rejected]
    }, {
        id: QUESTION_STATUS.rework.toString(),
        value: QUESTION_STATUS.rework.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUS.rework]
    }];
export var QUESTION_USERS_STATUS_OPTIONS = [{
        id: '',
        title: 'Не выбрано'
    }].concat(QUESTION_USERS_STATUS_OPTIONS_RAW);
export var QUESTION_USERS_STATUS_COLORS = (_QUESTION_USERS_STATU2 = {}, _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUS.approved, 'green'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUS.rework, 'yellow'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUS.expertise, 'red'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUS.editing, 'default'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUS.rejected, 'red'), _QUESTION_USERS_STATU2);
export var QUESTION_USERS_STATUS_OPTIONS_FOR_EXPERTISE = [{
        id: '',
        title: 'Не выбрано'
    }, {
        id: '1',
        title: 'Корректный'
    }, {
        id: '0',
        title: 'Некорректный'
    }];
export var QUESTION_USERS_STATUS_OPTIONS_FOR_APPROVE = [{
        id: '',
        title: 'Не выбрано'
    }, {
        id: 'rework',
        title: 'На доработку'
    }, {
        id: 'approved',
        title: 'Утверждён'
    }];
