var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { COLORS } from 'constants/colors';
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
export var Info = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-rows: min-content min-content auto;\n\n  padding: 32px 96px 32px 55px;\n  padding: 32px 33px 14px 32px;\n\n  background-color: ", ";\n"])), COLORS.PURPLE_500);
export var InfoList = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  align-items: center;\n  margin-top: 9px;\n"])));
export var InfoItem = styled(InfoList)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: ", ";\n  align-items: baseline;\n\n  margin-right: -32px;\n  margin-left: -32px;\n  padding: 16px 32px 16px 32px;\n\n  word-break: break-word;\n\n  border-bottom: 1px solid #d8d9f7;\n"])), function (_ref) {
    var isColumn = _ref.isColumn;
    return isColumn ? '1fr' : 'minmax(32px, 1fr) minmax(32px, 1fr)';
});
export var Type = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.WILD_BLUE_YONDER);
export var Name = styled(Type)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), COLORS.DARK_PURPLE_100);
export var StatusWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 24px;\n"])));
