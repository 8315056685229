import * as React from 'react';
import { isSelectedRole, specializationsApiSlice } from 'modules';
import { useTypedSelector } from 'store/store';
export var useSpecializationOptions = function useSpecializationOptions() {
    var role = useTypedSelector(isSelectedRole);
    var _specializationsApiSl = specializationsApiSlice.useGetSpecializationsQuery({
        role: role,
        params: {
            per_page: 500
        }
    }), specializationsData = _specializationsApiSl.data;
    var options = React.useMemo(function () {
        if (!specializationsData)
            return [];
        return specializationsData.data.map(function (specialization) {
            return {
                value: specialization.id,
                title: specialization.title
            };
        });
    }, [specializationsData]);
    return {
        specializations: (specializationsData === null || specializationsData === void 0 ? void 0 : specializationsData.data) || [],
        options: options
    };
};
