import { useParams } from 'react-router-dom';
import { useDefineSubdomain } from 'hooks/useDefineSubdomain';
import { employeeTestingApiSlice } from '../endpoints';
export var useEmployee = function useEmployee() {
    var _useDefineSubdomain = useDefineSubdomain(), isSubdomain = _useDefineSubdomain.isSubdomain, subdomain = _useDefineSubdomain.subdomain;
    var _useParams = useParams(), employeeCode = _useParams.employeeCode;
    var _employeeTestingApiSl = employeeTestingApiSlice.endpoints.getEmployeeTestingData.useQueryState({
        code: employeeCode,
        domain_code: subdomain
    }, {
        skip: !employeeCode || !isSubdomain
    }), employeeProfile = _employeeTestingApiSl.currentData;
    var employee = employeeProfile === null || employeeProfile === void 0 ? void 0 : employeeProfile[0];
    return {
        employee: employee
    };
};
