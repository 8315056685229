import { api } from 'services/api';
import { TAG_TYPES as TAG } from 'services/constants';
export var specializationsApiSlice = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getSpecializations: build.query({
                query: function query(_ref) {
                    var role = _ref.role, params = _ref.params;
                    return {
                        url: "/".concat(role, "/specialization/list"),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: [TAG.specializations]
            }),
            getSpecializationsWithMeta: build.query({
                query: function query(_ref2) {
                    var role = _ref2.role, params = _ref2.params;
                    return {
                        url: "/".concat(role, "/specialization/meta"),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: [TAG.specializationsWithMeta]
            }),
            createSpecialization: build.mutation({
                query: function query(body) {
                    return {
                        url: '/admin/specialization',
                        method: 'POST',
                        body: body
                    };
                },
                invalidatesTags: [TAG.specializations, TAG.specializationsWithMeta]
            }),
            updateSpecialization: build.mutation({
                query: function query(_ref3) {
                    var specializationId = _ref3.specializationId, data = _ref3.data;
                    return {
                        url: "/admin/specialization/".concat(specializationId),
                        method: 'PUT',
                        body: data
                    };
                },
                invalidatesTags: [TAG.specializations, TAG.specializationsWithMeta]
            }),
            deleteSpecialization: build.mutation({
                query: function query(_ref4) {
                    var specializationId = _ref4.specializationId;
                    return {
                        url: "/admin/specialization/".concat(specializationId),
                        method: 'DELETE'
                    };
                },
                invalidatesTags: [TAG.specializations, TAG.specializationsWithMeta]
            })
        };
    }
});
