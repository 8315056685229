import * as React from 'react';
import { sourcesApiSlice } from 'modules';
export var Chunk = function Chunk(props) {
    // additional required props are passed from ChunksLoader
    var _ref = props, role = _ref.role, directionFieldId = _ref.directionFieldId, knowledgeFieldId = _ref.knowledgeFieldId, filter = _ref.filter, chunkIndex = _ref.chunkIndex, onChunkDataCallback = _ref.onChunkDataCallback, limit = _ref.limit;
    var params = React.useMemo(function () {
        var offset = limit * chunkIndex;
        var result = {
            role: role,
            page: Math.floor(offset / limit) + 1,
            query: filter,
            functional_direction_id: directionFieldId
        };
        if (knowledgeFieldId) {
            result.knowledge_field_id = knowledgeFieldId;
        }
        return result;
    }, [chunkIndex, directionFieldId, filter, knowledgeFieldId, limit, role]);
    var _sourcesApiSlice$useG = sourcesApiSlice.useGetSourcesQuery(params, {
        skip: !directionFieldId
    }), sourcesData = _sourcesApiSlice$useG.data;
    React.useEffect(function () {
        onChunkDataCallback({
            data: sourcesData === null || sourcesData === void 0 ? void 0 : sourcesData.data,
            chunkIndex: chunkIndex
        });
    }, [chunkIndex, onChunkDataCallback, sourcesData === null || sourcesData === void 0 ? void 0 : sourcesData.data]);
    return null;
};
