import * as React from 'react';
import { Compass, Lamp, ManGear, Question, Stack } from 'assets/icons/others';
import { endpoints } from 'constants/endpoints';
import { useGetAllKnowlegesQuery } from 'services/knowledgeField';
import { useGetQuestionsQuery } from 'services/question';
import { isSelectedRole, sourcesApiSlice, specializationsApiSlice, funcDirectionsApiSlice } from 'modules';
import { useTypedSelector } from 'store/store';
export var useBases = function useBases() {
    var role = useTypedSelector(isSelectedRole);
    var _specializationsApiSl = specializationsApiSlice.useGetSpecializationsQuery({
        role: role
    }), specializationsData = _specializationsApiSl.data;
    var _funcDirectionsApiSli = funcDirectionsApiSlice.useGetDirectionsQuery({
        page: 1
    }), directionData = _funcDirectionsApiSli.data;
    var _sourcesApiSlice$useG = sourcesApiSlice.useGetSourcesQuery({
        page: 1
    }), sourceData = _sourcesApiSlice$useG.data;
    var _useGetAllKnowlegesQu = useGetAllKnowlegesQuery({
        page: 1
    }), knowledgeData = _useGetAllKnowlegesQu.data;
    var _useGetQuestionsQuery = useGetQuestionsQuery({
        page: 1
    }), questionsData = _useGetQuestionsQuery.data;
    var items = React.useMemo(function () {
        var result = [];
        if (specializationsData) {
            result.push({
                total: specializationsData.total || 0,
                title: 'Профессия/специализация',
                to: endpoints.adminSpecializations,
                icon: ManGear
            });
        }
        if (directionData !== null && directionData !== void 0 && directionData.meta) {
            result.push({
                total: directionData.meta.total || 0,
                title: 'Функциональные направления',
                to: endpoints.adminDirections.url,
                icon: Compass
            });
        }
        if (sourceData !== null && sourceData !== void 0 && sourceData.meta) {
            result.push({
                total: sourceData.meta.total || 0,
                title: 'Источники',
                to: endpoints.adminSources.url,
                icon: Stack
            });
        }
        if (knowledgeData !== null && knowledgeData !== void 0 && knowledgeData.meta) {
            result.push({
                total: knowledgeData.meta.total || 0,
                title: 'Области профессиональных знаний',
                to: endpoints.adminAllKnowledgeFields.url,
                icon: Lamp
            });
        }
        if (questionsData !== null && questionsData !== void 0 && questionsData.meta) {
            var _questionsData$meta$t;
            result.push({
                total: (_questionsData$meta$t = questionsData.meta.total) !== null && _questionsData$meta$t !== void 0 ? _questionsData$meta$t : 0,
                title: 'Вопросы',
                to: endpoints.adminQuestions.url,
                icon: Question
            });
        }
        return result;
    }, [directionData, knowledgeData, questionsData, sourceData, specializationsData]);
    return {
        items: items
    };
};
