import * as React from 'react';
import { isSelectedRole } from 'modules';
import { useTypedSelector } from 'store/store';
import { funcDirectionsApiSlice } from '../endpoints';
export var useDirectionOptions = function useDirectionOptions(_ref) {
    var specializationId = _ref.specializationId;
    var role = useTypedSelector(isSelectedRole);
    var _funcDirectionsApiSli = funcDirectionsApiSlice.useGetDirectionsQuery({
        page: 1,
        per_page: 500,
        role: role,
        specialization_id: specializationId
    }), directionsData = _funcDirectionsApiSli.data;
    var options = React.useMemo(function () {
        if (!directionsData)
            return [];
        return directionsData.data.map(function (direction) {
            return {
                value: direction.id,
                title: direction.title
            };
        });
    }, [directionsData]);
    return {
        directions: (directionsData === null || directionsData === void 0 ? void 0 : directionsData.data) || [],
        options: options
    };
};
