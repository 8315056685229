var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { Dropdown as _Dropdown } from 'components/UI/molecules';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 40px;\n  align-items: center;\n"])));
export var SourceWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  align-items: center;\n"])));
export var Option = styled(SourceWrapper)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  grid-gap: 8px;\n"])));
export var MultipleWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 4px;\n  align-items: center;\n"])));
export var LabelWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
export var LabelText = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
export var AnswerWrappers = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  align-items: center;\n"])));
export var AnswerWrapper = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  grid-template-columns: ", ";\n  align-items: center;\n"])), function (_ref) {
    var index = _ref.index, isRangingOrFilling = _ref.isRangingOrFilling;
    if (index === 0) {
        return "".concat(isRangingOrFilling ? '100px' : 'auto', " 1fr ").concat(isRangingOrFilling ? '' : 'auto');
    }
    if (index === 1) {
        return "".concat(isRangingOrFilling ? '100px' : 'auto', " 1fr 48px ").concat(isRangingOrFilling ? '' : 'auto');
    }
    return "".concat(isRangingOrFilling ? '100px' : 'auto', " 1fr 48px 48px ").concat(isRangingOrFilling ? '' : 'auto');
});
export var FieldWrapper = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 8px;\n"])));
export var Dropdown = styled(_Dropdown)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n"])), function (_ref2) {
    var withIndex = _ref2.withIndex;
    return withIndex ? 'margin-left: 29px' : '';
});
export var RangingWrap = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n"])));
