var _SOURCE_TYPE_TEXT;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
export var SOURCE_TYPE = {
    regulation: 10,
    statute: 20,
    technicalMap: 30,
    sto: 40,
    technicalList: 50,
    ppr: 60,
    gost: 70,
    snip: 80,
    iso: 90,
    order: 110,
    decree: 120,
    law: 130,
    other: 140
};
export var SOURCE_TYPE_TEXT = (_SOURCE_TYPE_TEXT = {}, _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.regulation, 'Регламент'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.statute, 'Положение'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.technicalMap, 'Технологическая карта'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.sto, 'Cтандарт организации (СТО)'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.technicalList, 'Технологическая записка'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.ppr, 'Проект производства работ (ППР)'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.gost, 'ГОСТ'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.snip, 'СНиП'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.iso, 'ИСО'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.order, 'Приказ'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.decree, 'Постановление'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.law, 'Федеральный закон'), _defineProperty(_SOURCE_TYPE_TEXT, SOURCE_TYPE.other, 'Другое'), _SOURCE_TYPE_TEXT);
export var SOURCE_TYPE_OPTIONS = [{
        value: SOURCE_TYPE.regulation,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.regulation]
    }, {
        value: SOURCE_TYPE.statute,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.statute]
    }, {
        value: SOURCE_TYPE.technicalMap,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.technicalMap]
    }, {
        value: SOURCE_TYPE.sto,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.sto]
    }, {
        value: SOURCE_TYPE.technicalList,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.technicalList]
    }, {
        value: SOURCE_TYPE.ppr,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.ppr]
    }, {
        value: SOURCE_TYPE.gost,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.gost]
    }, {
        value: SOURCE_TYPE.snip,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.snip]
    }, {
        value: SOURCE_TYPE.iso,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.iso]
    }, {
        value: SOURCE_TYPE.order,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.order]
    }, {
        value: SOURCE_TYPE.decree,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.decree]
    }, {
        value: SOURCE_TYPE.law,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.law]
    }, {
        value: SOURCE_TYPE.other,
        title: SOURCE_TYPE_TEXT[SOURCE_TYPE.other]
    }];
