import * as React from 'react';
import { QUESTION_USERS_STATUS_TEXT as STATUS_TEXT, QUESTION_USERS_STATUS_OPTIONS_RAW as STATUS_OPTIONS_RAW } from 'constants/questionStatus';
import { useDefineSubdomain } from 'hooks/useDefineSubdomain';
import { QUESTION_STATUS as STATUS } from '../constants';
export var useQuestionStatusOptions = function useQuestionStatusOptions() {
    var _useDefineSubdomain = useDefineSubdomain(), isSubdomain = _useDefineSubdomain.isSubdomain;
    var options = React.useMemo(function () {
        if (isSubdomain) {
            return [{
                    value: STATUS.approved.toString(),
                    title: STATUS_TEXT[STATUS.approved]
                }, {
                    value: STATUS.expertise.toString(),
                    title: STATUS_TEXT[STATUS.expertise]
                }];
        }
        return STATUS_OPTIONS_RAW;
    }, [isSubdomain]);
    return {
        options: options
    };
};
