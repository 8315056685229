var _excluded = ["role"], _excluded2 = ["uuid", "role"], _excluded3 = ["role"];
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
function _objectWithoutProperties(source, excluded) { if (source == null)
    return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0)
            continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key))
            continue;
        target[key] = source[key];
    }
} return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null)
    return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
        continue;
    target[key] = source[key];
} return target; }
import { api } from './api';
var url = 'knowledge-field';
export var knowledgesApiSlice = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getAllKnowleges: build.query({
                query: function query(_ref) {
                    var _ref$role = _ref.role, role = _ref$role === void 0 ? 'admin' : _ref$role, params = _objectWithoutProperties(_ref, _excluded);
                    return {
                        url: "".concat(role, "/").concat(url),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: function providesTags(result) {
                    return result !== null && result !== void 0 && result.data ? [].concat(_toConsumableArray(result.data.map(function (_ref2) {
                        var id = _ref2.id;
                        return {
                            type: 'KnowledgeField',
                            id: id
                        };
                    })), [{
                            type: 'KnowledgeField',
                            id: 'LIST'
                        }]) : [{
                            type: 'KnowledgeField',
                            id: 'LIST'
                        }];
                }
            }),
            getKnowlegesUpdates: build.query({
                query: function query(params) {
                    return {
                        url: "admin/updates/".concat(url),
                        method: 'GET',
                        params: params
                    };
                }
            }),
            getKnowleges: build.query({
                query: function query(_ref3) {
                    var uuid = _ref3.uuid, _ref3$role = _ref3.role, role = _ref3$role === void 0 ? 'admin' : _ref3$role;
                    return {
                        url: "".concat(role, "/").concat(url, "/functional-direction/").concat(uuid),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(result) {
                    return result ? [].concat(_toConsumableArray(result.map(function (_ref4) {
                        var id = _ref4.id;
                        return {
                            type: 'KnowledgeField',
                            id: id
                        };
                    })), [{
                            type: 'KnowledgeField',
                            id: 'LIST'
                        }]) : [{
                            type: 'KnowledgeField',
                            id: 'LIST'
                        }];
                }
            }),
            editKnowledge: build.mutation({
                query: function query(_ref5) {
                    var uuid = _ref5.uuid, _ref5$role = _ref5.role, role = _ref5$role === void 0 ? 'admin' : _ref5$role, body = _objectWithoutProperties(_ref5, _excluded2);
                    return {
                        url: "".concat(role, "/").concat(url, "/").concat(uuid),
                        method: 'PUT',
                        body: body
                    };
                },
                invalidatesTags: function invalidatesTags(result) {
                    return [{
                            type: 'KnowledgeField',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            removeKnowlegde: build.mutation({
                query: function query(_ref6) {
                    var uuid = _ref6.uuid, _ref6$role = _ref6.role, role = _ref6$role === void 0 ? 'admin' : _ref6$role;
                    return {
                        url: "".concat(role, "/").concat(url, "/").concat(uuid),
                        method: 'DELETE'
                    };
                },
                invalidatesTags: [{
                        type: 'KnowledgeField',
                        id: 'LIST'
                    }]
            }),
            createKnowledge: build.mutation({
                query: function query(_ref7) {
                    var _ref7$role = _ref7.role, role = _ref7$role === void 0 ? 'admin' : _ref7$role, body = _objectWithoutProperties(_ref7, _excluded3);
                    return {
                        url: "".concat(role, "/").concat(url),
                        method: 'POST',
                        body: body
                    };
                },
                invalidatesTags: [{
                        type: 'KnowledgeField',
                        id: 'LIST'
                    }]
            }),
            getKnowlege: build.query({
                query: function query(_ref8) {
                    var uuid = _ref8.uuid, _ref8$role = _ref8.role, role = _ref8$role === void 0 ? 'admin' : _ref8$role;
                    return {
                        url: "".concat(role, "/").concat(url, "/").concat(uuid, "/edit"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(result) {
                    return [{
                            type: 'KnowledgeField',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            activateKnowledge: build.query({
                query: function query(_ref9) {
                    var uuid = _ref9.uuid;
                    return {
                        url: "admin/".concat(url, "/activate/").concat(uuid),
                        method: 'GET'
                    };
                }
            }),
            deactivateKnowledge: build.query({
                query: function query(_ref10) {
                    var uuid = _ref10.uuid;
                    return {
                        url: "admin/".concat(url, "/deactivate/").concat(uuid),
                        method: 'GET'
                    };
                }
            })
        };
    }
});
var useGetAllKnowlegesQuery = knowledgesApiSlice.useGetAllKnowlegesQuery, useCreateKnowledgeMutation = knowledgesApiSlice.useCreateKnowledgeMutation, useEditKnowledgeMutation = knowledgesApiSlice.useEditKnowledgeMutation, useGetKnowlegeQuery = knowledgesApiSlice.useGetKnowlegeQuery, useGetKnowlegesQuery = knowledgesApiSlice.useGetKnowlegesQuery, useRemoveKnowlegdeMutation = knowledgesApiSlice.useRemoveKnowlegdeMutation, useLazyActivateKnowledgeQuery = knowledgesApiSlice.useLazyActivateKnowledgeQuery, useLazyDeactivateKnowledgeQuery = knowledgesApiSlice.useLazyDeactivateKnowledgeQuery, useGetKnowlegesUpdatesQuery = knowledgesApiSlice.useGetKnowlegesUpdatesQuery;
export { useGetAllKnowlegesQuery, useCreateKnowledgeMutation, useEditKnowledgeMutation, useGetKnowlegeQuery, useGetKnowlegesQuery, useRemoveKnowlegdeMutation, useLazyActivateKnowledgeQuery, useLazyDeactivateKnowledgeQuery, useGetKnowlegesUpdatesQuery };
