var _DEFAULT_ROLES_PATHS, _DEFAULT_SUBDOMAIN_RO;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
import { ROLE } from 'modules';
import { endpoints } from 'constants/endpoints';
export var DEFAULT_ROLES_PATHS = (_DEFAULT_ROLES_PATHS = {}, _defineProperty(_DEFAULT_ROLES_PATHS, ROLE.admin, endpoints.adminClients.url), _defineProperty(_DEFAULT_ROLES_PATHS, ROLE.redactor, endpoints.redactorQuestions.url), _defineProperty(_DEFAULT_ROLES_PATHS, ROLE.expert, endpoints.expertQuestions.url), _defineProperty(_DEFAULT_ROLES_PATHS, ROLE.employee, '/'), _DEFAULT_ROLES_PATHS);
export var DEFAULT_SUBDOMAIN_ROLES_PATHS = (_DEFAULT_SUBDOMAIN_RO = {}, _defineProperty(_DEFAULT_SUBDOMAIN_RO, ROLE.admin, endpoints.clientSpecializations), _defineProperty(_DEFAULT_SUBDOMAIN_RO, ROLE.redactor, endpoints.redactorQuestions.url), _defineProperty(_DEFAULT_SUBDOMAIN_RO, ROLE.expert, endpoints.expertQuestions.url), _defineProperty(_DEFAULT_SUBDOMAIN_RO, ROLE.employee, '/'), _DEFAULT_SUBDOMAIN_RO);
